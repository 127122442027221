<style scoped>
	.tpm_box{width: 100%;height: 100%;display: flex;flex-direction: column;}
	.tem_body{flex: 1;width: 100%;}
	.tem_header{padding: 5px;display: flex;align-items: center;};
	.tem_body_contair{height: 100%;width: 100%;}
	.tem_footer{text-align: right;padding: 5px;}
	.mb{padding: 0 20px 0 10px;}
	/* 弹窗 */
	.el-dialog__wrapper{position: absolute;left: 0;top: 0;display: flex;align-items: center;overflow: hidden;}
	.el-dialog.my_dialog{margin-top: 0;}
</style>
<style>
	table{box-sizing: border-box;}
	/* 用来设置当前页面element全局table 选中某行时的背景色*/
	.el-table__body tr.current-row>td{
	  background-color: #67CD80 !important;
	  color: #fff;
	}
	.mb .el-breadcrumb__inner{font-weight: bold!important;font-size: 1.1rem;}
</style>
<template>
	<div class="tpm_box">
		<div class="tem_header">
			<div class="mb">
				<el-breadcrumb separator-class="el-icon-arrow-right">

				</el-breadcrumb>
			</div>
			<el-button type="danger" size="small" @click="thisReload">刷新<i class="el-icon-refresh-right"></i></el-button>
		</div>
		<div class="tem_body" id="tem_body">
			<div class="tem_body_contair" id="table_box" v-loading="tableLoading">
				<el-table :data="tableData" :height="tableHeight" border >
					<el-table-column prop="label" label="分类" width=""></el-table-column>
					<el-table-column prop="name" label="项目" width=""></el-table-column>
					<el-table-column prop="create_time" label="创建时间" width=""></el-table-column>
				</el-table>
			</div>
		</div>
		<div class="tem_footer">
			<el-pagination @current-change="handleCurrentChange" :current-page="pageNumber" :page-sizes="[20]" :page-size="pageSize"
			 layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
			</el-pagination>
		</div>

	</div>
</template>

<script>
	var _this;
	export default {
		components: {

		},
		props: ['pageUpParams'],
		data() {
			return {
				pageParams:null,
				tableLoading: true,
				tableHeight: 0,
				tableData: [],
				pageNumber: 1,
				pageSize: 20,
				pageTotal: 0,
			}
		},
		created: function() {
			_this = this;
			_this.pageParams = _this.pageUpParams;
			console.log(_this.pageParams)
		},
		mounted: function() {
			_this.$nextTick(function() {
				_this.tableHeight = document.getElementById("tem_body").offsetHeight;
				_this.api_getTableData();
			})
		},
		methods: {
			/* * 刷新 */
			thisReload() {
				_this.selectTableRow = null;
				_this.tableHeight = document.getElementById("tem_body").offsetHeight;
				_this.api_getTableData();
			},
			/* * 切换分页 */
			handleCurrentChange(val) {
				_this.pageNumber = val;
				_this.api_getTableData();
			},
			/* * 获取表格数据 */
			api_getTableData() {
				_this.tableLoading = true;
				_this._postApi('/wy/chargeItem/getItemPageListByResidence', {
					residence_id: _this.pageParams.community.id,
					page:_this.pageNumber,
					limit:_this.pageSize
				}).then((res) => {
					if (res.code == 1) {
						var baseData = res.data;
						_this.tableData = baseData.list;
						_this.tableLoading = false;
					} else {
				
					}
				}).catch((err) => {});
			},
		}
	}
</script>
