<style scoped>
	.dialog_page_footer{text-align: right;}
	.community_box{max-height: 12rem;overflow-y: auto;}
</style>

<template>
	<div class="dialog_page">
		<div class="dialog_page_header"></div>
		<div class="dialog_page_body">
			<el-form label-width="100px">
				<el-row :gutter="20">
					<el-col :span="24">
						<el-form-item label="">
							<el-breadcrumb separator-class="el-icon-arrow-right">
								<el-breadcrumb-item>{{pageParams.community.name}}</el-breadcrumb-item>
								<el-breadcrumb-item>{{pageParams.classify.name}}</el-breadcrumb-item>
								<el-breadcrumb-item>{{pageParams.thisParams.name}}</el-breadcrumb-item>
							</el-breadcrumb>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="公式名称">
							<el-input placeholder="请输入公式名称" v-model="formData.name"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20">
					<el-col :span="24">
						<el-form-item label="计费参数">
							<el-select v-model="formData.charge_param" placeholder="请选择">
								<el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20">
					<el-col :span="24">
						<el-form-item label="定额单价(元)">
							<el-input placeholder="请输入定额单价" v-model="formData.fixed_unit_price"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20">
					<el-col :span="24">
						<el-form-item label="固定系数">
							<el-input placeholder="请输入固定系数" v-model="formData.fixed_coefficient"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</div>
		<div class="dialog_page_footer">
			<el-button @click="clickCancel">取消</el-button>
			<el-button type="success" :loading="btnSaveLoading" @click="api_saveCommit">立即保存</el-button>
		</div>
	</div>
</template>

<script>
	import {chargeParam} from '../../../assets/js/config.js' //注意路径
	var _this;
	export default {
		props: ['pageParams'],
		data() {
			return {
				btnSaveLoading: false,
				formData: {
					name: '',
					charge_param:'1',
					fixed_unit_price:'',
					fixed_coefficient:''
				},
				options:[],
			}
		},
		created: function() {
			_this = this;
			_this.apiGetChargeParam();
			
		},
		mounted: function() {

		},
		methods: {
			/** ->  获取计费方式选项
			AUTHOR:郑凯 
			CREATE_TIME:2021-2-23 16:18:36 */
			apiGetChargeParam(){
				_this._getApi('/wy/chargeItem/getBillingPlan',{
					
				}).then(function(res){
					var baseData = res.data;
					baseData.forEach(function(item){
						item.id = item.id + '';
					})
					_this.options = baseData;
				}).catch(function(err){
					
				})
			},
			
			// _ ** 点击取消
			clickCancel() {
				_this.$emit('childrenEvent', {
					type: 'close'
				});
			},

			// _ ** 添加保存
			api_saveCommit() {
				_this.btnSaveLoading = true;
				var params = _this.formData;
				params.item_id = _this.pageParams.thisParams.id;
				_this._postApi('/wy/chargeItem/formulaCreate', params).then((res) => {
					if (res.code == 1) {
						_this.btnSaveLoading = false;
						_this.$emit('childrenEvent', {
							type: 'success'
						});
					} else {
						_this.btnSaveLoading = false;
						_this.$confirm(res.msg, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {

						}).catch(() => {

						});
					}
				}).catch((err) => {

				})
			}
		}
	}
</script>
