<style scoped>
	.dialog_page_footer {
		text-align: right;
	}
	.community_box {
		max-height: 12rem;
		overflow-y: auto;
	}
	.form-item-discribe {
		font-size: 13px;
		color: #555;
		line-height: 2;
	}
</style>

<template>
	<div class="dialog_page">
		<div class="dialog_page_header"></div>
		<div class="dialog_page_body">
			<el-form label-width="150px">
				<el-row :gutter="20">
					<el-col :span="24">
						<el-form-item label="">
							<el-breadcrumb separator-class="el-icon-arrow-right">
								<el-breadcrumb-item>{{pageParams.community.name}}</el-breadcrumb-item>
								<el-breadcrumb-item>{{pageParams.thisParams.name}}</el-breadcrumb-item>
							</el-breadcrumb>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="收费项目名称">
							<el-input placeholder="请输入收费项目名称" v-model="formData.name"></el-input>
						</el-form-item>
						<el-form-item label="违约金日利率">
							<el-input placeholder="‰" v-model="formData.penalty_rate"></el-input>
						</el-form-item>
						<el-form-item label="每月几日开始计算">
							<el-input @input="inputPenaltyDay" placeholder="不填写默认每月月底最后一天"
								v-model="formData.penalty_day"></el-input>
							<div class="form-item-discribe">* 只能填写 1-28 日</div>
						</el-form-item>
						<el-form-item label="计算方式">
							<el-input disabled placeholder="单利" value="单利"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<!-- <el-row :gutter="20">
					<el-col :span="24">
						<el-form-item label="状态">
							<el-switch v-model="formData.status" active-text="开启" inactive-text="禁用" active-value="1" inactive-value="99"></el-switch>
						</el-form-item>
					</el-col>
				</el-row> -->
				<el-row :gutter="20">

				</el-row>
			</el-form>
		</div>
		<div class="dialog_page_footer">
			<el-button @click="clickCancel">取消</el-button>
			<el-button type="success" :loading="btnSaveLoading" @click="api_saveCommit">立即保存</el-button>
		</div>
	</div>
</template>

<script>
	var _this;
	export default {
		props: ['pageParams'],
		data() {
			return {
				btnSaveLoading: false,
				formData: {
					name: '',
					penalty_rate: '',
					penalty_day: '',
					penalty_compute_type: 1
				}
			}
		},
		created: function() {
			_this = this;
			_this.formData = _this.pageParams.operateObject;
			_this.formData.status = _this.formData.status + '';
		},
		mounted: function() {

		},
		methods: {
			/**
			 * @discribe 
			 * @author:郑凯 
			 * @create_time:2021-3-23 10:31:49 
			 */
			inputPenaltyDay(val) {
				if (val > 28) {
					_this.formData.penalty_day = 28;
				}
				if (val < 1 && val != "") {
					_this.formData.penalty_day = 1;
				}
			},
			// _ ** 点击取消
			clickCancel() {
				_this.$emit('childrenEvent', {
					type: 'close'
				});
			},

			// _ ** 添加保存
			api_saveCommit() {
				_this.btnSaveLoading = true;
				var params = _this.formData;
				_this._postApi('/wy/chargeItem/itemUpdate', params).then((res) => {
					if (res.code == 1) {
						_this.btnSaveLoading = false;
						_this.$emit('childrenEvent', {
							type: 'success'
						});
					} else {
						_this.btnSaveLoading = false;
						_this.$confirm(res.msg, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {

						}).catch(() => {

						});
					}
				}).catch((err) => {

				})
			}
		}
	}
</script>
