<style scoped>

</style>
<template>
	<div class="con_page_container">
		<!-- 左边数据树 -->
		<div class="left_tree_box">
			<el-tree :props="props" node-key="id" ref="tree" :current-node-key="selectCommunityId" :load="loadNode" lazy
			 :expand-on-click-node="false" :highlight-current="true" @node-click="treeClick"></el-tree>
		</div>
		<!-- 内容 -->
		<div class="main_container">
			<div class="ch_temp_box">
				<index_list :pageUpParams="calssifyPageParams" v-if="openTempView.type==8" :key="showTempleteKey"></index_list>
				<!-- 常规类 -->
				<Collect_fees_routine v-if="openTempView.type==1" v-on:childrenEvent="calssifyEvent" :pageUpParams="calssifyPageParams"></Collect_fees_routine>
				<!-- 押金类 -->
				<Collect_fees_deposit v-if="openTempView.type==2" v-on:childrenEvent="calssifyEvent" :pageUpParams="calssifyPageParams"></Collect_fees_deposit>
				<!-- 临时类 -->
				<Collect_fees_temporary v-if="openTempView.type==3" v-on:childrenEvent="calssifyEvent" :pageUpParams="calssifyPageParams"></Collect_fees_temporary>
				<!-- 仪表类 -->
				<Collect_fees_meter v-if="openTempView.type==4" v-on:childrenEvent="calssifyEvent" :pageUpParams="calssifyPageParams"></Collect_fees_meter>
				<!-- 常规类公示列表 -->
				<routine_list v-if="openTempView.type==5" v-on:childrenEvent="calssifyEvent" :pageUpParams="calssifyPageParams"></routine_list>
				<meter_list v-if="openTempView.type==6" v-on:childrenEvent="calssifyEvent" :pageUpParams="calssifyPageParams"></meter_list>
				<temporary_list v-if="openTempView.type==7" v-on:childrenEvent="calssifyEvent" :pageUpParams="calssifyPageParams"></temporary_list>
			</div>
		</div>
	</div>
</template>

<script>
	import index_list from './index_list.vue';
	import Collect_fees_routine from './Collect_fees_routine.vue';
	import Collect_fees_deposit from './Collect_fees_deposit.vue';
	import Collect_fees_temporary from './Collect_fees_temporary.vue';
	import Collect_fees_meter from './Collect_fees_meter.vue';
	import routine_list from './routine/list.vue';
	import meter_list from './meter/list.vue';
	import temporary_list from './temporary/list.vue';
	var _this;
	export default {
		components: {
			index_list,
			Collect_fees_routine,
			Collect_fees_deposit,
			Collect_fees_temporary,
			Collect_fees_meter,
			routine_list,
			meter_list
		},
		data() {
			return {
				communityList: [], //小区列表
				props: {
					label: 'name',
					children: 'zones',
					isLeaf: 'leaf'
				},
				selectCommunityId: 0,
				openTempView: {
					type: 0
				},
				calssifyPageParams: null,

				unitsTemView: false, // 单元组件显示
				unitsPageParams: null,
				roomTemView: false, // 房间列表组件显示
				roomPageParams: null,
				showTempleteKey:0,//
			}
		},
		created: function() {
			_this = this;
		},
		mounted: function() {

		},
		methods: {
			/* * 获取小区列表 tree加载 */
			loadNode(node, resolve) {
				if (node.level === 0) {
					//获取小区
					_this._getApi('/wy/account/getResidenceListByAdmin', {}).then((res) => {
						if (res.code == 1) {
							_this.communityList = res.data;
							_this.selectCommunityId = _this.communityList[0].id;
							
							var baseData = _this.communityList;
							baseData.forEach(function(item) {
								item.leaf = false;
							});
							_this.$nextTick(function() {
								var baseData1 = {};
								var community1 = baseData[0];
								baseData1.community = community1;
								_this.calssifyPageParams = baseData1;
								
								_this.openTempView.type = 8;
								_this.$refs.tree.setCurrentKey(_this.selectCommunityId); //获取已经设置的资源后渲染
							});
							return resolve(baseData);

						} else {

						}
					}).catch((err) => {})
				}
				if (node.level === 1) {
					//获取小区下数据
					_this._postApi('/wy/chargeItem/getList', {
						residence_id: node.data.id
					}).then((res) => {
						if (res.code == 1) {
							var baseData = res.data;
							baseData.forEach(function(item) {
								item.name = item.label;
							})
							return resolve(baseData);
						} else {

						}
					}).catch((err) => {});
				}
				if (node.level === 2) {
					_this._postApi('/wy/chargeItem/getItemList', {
						residence_id: node.parent.data.id,
						type: node.data.id
					}).then((res) => {
						if (res.code == 1) {
							var dataList = res.data;
							dataList.forEach(function(item) {
								item.leaf = true;
							})
							return resolve(dataList);
						} else {

						}
					}).catch((err) => {});
				}
			},

			/* * tree 点击 */
			treeClick(data, node, _this_) {
				if (node.level == 1) {
					var baseData = {};
					var community = node.data;
					baseData.community = community;
					_this.calssifyPageParams = baseData;
					_this.openTempView.type = 8;
					_this.showTempleteKey += 1;
				} else if (node.level == 2) {
					var baseData = {};
					var community = node.parent.data;
					baseData.community = community;
					baseData.thisParams = data;
					_this.calssifyPageParams = baseData;
					if (node.data.id == 1) {
						//常规类
						_this.openTempView.type = -1;
						_this.$nextTick(function() {
							_this.openTempView.type = 1;
						})
					} else if (node.data.id == 2) {
						//押金类
						_this.openTempView.type = -1;
						_this.$nextTick(function() {
							_this.openTempView.type = 2;
						})
					} else if (node.data.id == 3) {
						//临时类
						_this.openTempView.type = -1;
						_this.$nextTick(function() {
							_this.openTempView.type = 3;
						})
					} else if (node.data.id == 4) {
						//仪表类
						_this.openTempView.type = -1;
						_this.$nextTick(function() {
							_this.openTempView.type = 4;
						})
					}
				} else if (node.level == 3) {
					var baseData = {};
					baseData.community = node.parent.parent.data;
					baseData.classify = node.parent.data;
					baseData.thisParams = data;
					_this.calssifyPageParams = baseData;
					//常规类公示列表
					_this.openTempView.type = -1;
					if (node.parent.data.id == 1) {
						_this.$nextTick(function() {
							_this.openTempView.type = 5;
						})
					}else if(node.parent.data.id == 4){
						_this.$nextTick(function() {
							_this.openTempView.type = 6;
						})
					}else if(node.parent.data.id == 3){
						_this.$nextTick(function() {
							_this.openTempView.type = 7;
						})
					}
					
				}
			},

			/* *  组件通信 */
			calssifyEvent(e) {
				switch (e.type) {
					case 'add':
						var node = _this.$refs.tree.getNode(_this.calssifyPageParams.thisParams.id); //要刷新的tree id
						node.loaded = false;
						node.expand();
						break;
				}
			},
			/* *  组件通信 */
			unitsEvent(e) {
				switch (e.type) {
					case 'add':
						var node = _this.$refs.tree.getNode(_this.unitsPageParams.build.id); //要刷新的tree id
						node.loaded = false;
						node.expand();
						break;
				}
			},

		}
	}
</script>
